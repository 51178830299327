import { EvaluationLayoutComponent } from "../layouts/evaluation_layout/evaluation_layout.component"
import {
    GenerarEnsayoMultipleComponent,
    EnsayoInstanciasComponent,
    EvaluacionComponent,
    EvaluacionesCorregirComponent,
    GenerarEnsayoProfesorComponent,
    RealizarEnsayoComponent,
    ResultadosEnsayoAlumnoComponent,
    ResultadosEnsayoComponent,
    RevisarEnsayosComponent,
    RevisarSimulacrosComponent,
    LibroNotasComponent,
    InformeAlumnoComponent
} from "@puntaje/puntaje/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { HomeSaberComponent } from "./home_saber.component"
import { HomeSaber11Component } from "./home_saber11.component"
import { HomePruebasComponent } from "./home_pruebas.component"

import { EstadisticasComponent, ProgresoComponent } from "@puntaje/puntaje/core"

import { EstadisticasMultiplesComponent } from "@puntaje/puntaje/core"

import { ResultadosEvaluacionComponent } from "./resultados-evaluacion/resultados-evaluacion.component"

export const routes: Routes = [
    {
        path: "evaluaciones/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ResultadosEvaluacionComponent, canActivate: [AuthGuard] }]
    },
    {
        path: "ensayo_instancias",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/generar_ensayo2/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoProfesorComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayos/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayo_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },

    {
        path: "evaluaciones/resultados/historial",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/resultados/libro",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: LibroNotasComponent,
                canActivate: [AuthGuard],
                data: {
                    disableReforzamiento: true
                }
            }
        ]
    },
    {
        path: "evaluaciones/resultados/informe_alumno",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: InformeAlumnoComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "evaluaciones/generar_ensayo/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    enableReporte: false
                }
            }
        ]
    },
    {
        path: "evaluaciones/generar_ensayo2/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoProfesorComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id/realizar",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: RealizarEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/subir",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionesCorregirComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluacion_instancias/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "evaluaciones/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EvaluacionComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "ensayo_instancias_estudiante/:id",
        component: EvaluationLayoutComponent,
        children: [{ path: "", component: ResultadosEnsayoAlumnoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },

    {
        path: "saber/generar_simulacro/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    enableReporte: false
                }
            }
        ]
    },
    {
        path: "saber/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber/estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber/progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeSaberComponent, canActivate: [TokenGuard, AuthGuard] }]
    },

    {
        path: "saber11/generar_simulacro/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    enableReporte: false
                }
            }
        ]
    },
    {
        path: "saber11/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber11/estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber11/estadisticas_multiples",
        component: EstadisticasMultiplesComponent,
        canActivate: [TokenGuard, AuthGuard]
    },
    {
        path: "saber11/progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber11/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber11",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomeSaber11Component, canActivate: [TokenGuard, AuthGuard] }]
    },

    {
        path: "saber11/generar_simulacro",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: GenerarEnsayoMultipleComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "saber11/simulacros_compartidos",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarSimulacrosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/generar_prueba/:asignatura_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    enableReporte: false
                }
            }
        ]
    },
    {
        path: "pruebas/generar_prueba",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: GenerarEnsayoProfesorComponent,
                canActivate: [TokenGuard, AuthGuard],
                data: {
                    enableReporte: false
                }
            }
        ]
    },
    {
        path: "pruebas/compartidas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: RevisarEnsayosComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/estadisticas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EstadisticasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/progreso",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ProgresoComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas/resultados",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: EnsayoInstanciasComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "pruebas",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: HomePruebasComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const routing = RouterModule.forChild(routes)
