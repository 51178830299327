import {
    Component,
    OnInit,
    ViewChild,
    Input,
    SimpleChanges,
    OnDestroy,
    ChangeDetectorRef,
    OnChanges
} from "@angular/core"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Estadisticas, EvaluacionInstancias, EvaluacionTipo } from "@puntaje/puntaje/api-services"
import { DatePipe } from "@angular/common"
import { Store, select } from "@ngrx/store"
import { combineLatest, Subscription } from "rxjs"
import { selectAsignaturasByEvaluacionTipo, selectEvaluacionTipoAliases, State } from "@puntaje/puntaje/store"
import { filter, map } from "rxjs/operators"
import { EstadisticasEvolutivasService } from "../estadisticas_evolutivas.service"
import { AppEnv } from "@puntaje/shared/core"

@Component({
    selector: "informe-alumno",
    templateUrl: "informe-alumno.component.html",
    styleUrls: ["informe-alumno.component.scss"]
})
export class InformeAlumnoComponent implements OnInit, OnChanges, OnDestroy {
    idUsuarios: number[]
    idGrupos: number[]
    timeFrame: Date[]
    nivelIds: number[]
    sub: Subscription
    evaluacionTipoAliases: any

    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )
    asignaturasByEvaluacionTipo: any
    asignaturasByEvaluacionTipo$ = this.store.pipe(
        select(selectAsignaturasByEvaluacionTipo),
        filter(x => !!x)
    )

    @Input() establecimientoId: number
    @Input() usuarioId: number
    @Input() data: any
    @Input() fechaInicio: any
    @Input() fechaFin: any
    @Input() evaluacionTipos: string[]
    @Input() evaluacionTiposSelected: EvaluacionTipo[] = []

    today: string = new DatePipe("en-US").transform(new Date(), "dd-MM-yyyy")
    year: number = new Date().getFullYear()

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    hasAchievements = !!this.environment.endpoints.achievements

    constructor(
        protected estadisticasService: Estadisticas,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected estadisticasEvolutivasService: EstadisticasEvolutivasService,
        protected environment: AppEnv
    ) { }

    ngOnInit() {
        this.sub = combineLatest([this.evaluacionTipoAliases$, this.asignaturasByEvaluacionTipo$]).subscribe(
            ([evaluacionTipoAliases, asignaturasByEvaluacionTipo]) => {
                this.evaluacionTipoAliases = evaluacionTipoAliases
                this.asignaturasByEvaluacionTipo = asignaturasByEvaluacionTipo
                this.setData()
            }
        )
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes &&
            changes["usuarioId"] &&
            !changes["usuarioId"].isFirstChange() &&
            changes["usuarioId"].currentValue !== null &&
            changes["usuarioId"].currentValue !== undefined
        ) {
            this.setData()
        }
    }

    async setData() {
        if (!this.usuarioId) return

        this.loadingLayout.standby()
        this.data = null

        const params = {
            usuario_id: this.usuarioId,
            establecimiento_id: this.establecimientoId,
            fecha_inicio: this.fechaInicio,
            fecha_fin: this.fechaFin,
            evaluacion_tipos: this.evaluacionTiposSelected.map(et => et.evaluacion_tipo)
        }
        this.data = await this.estadisticasService.informeUsoAlumno(params)

        this.loadingLayout.ready()
        this.cdr.detectChanges()
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
    }
}
