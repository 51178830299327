<loading-layout #loadingLayout>
    <div *ngIf="data">
        <p class="pull-right">Fecha: {{ today }}</p>
        <br />
        <h2 class="main-title">
            {{ "informe_alumno.informe.titulo" | t }}
            <br />
            {{ year }}
        </h2>
        <br />
        <p id="iua_descripcion">
            {{ "informe_alumno.informe.descripcion" | t }}
        </p>

        <b id="iua_nombre">{{ data.info_alumno.nombre_completo }}</b>
        <br />
        <b id="iua_rut">{{ data.info_alumno.rut }}</b>
        <br />
        <ng-container *ngIf="data.info_alumno.fecha_nacimiento">
            <b id="iua_fecha_nacimiento">{{ data.info_alumno.fecha_nacimiento }}</b>
            <br />
        </ng-container>
        <b id="iua_email">{{ data.info_alumno.email }}</b>
        <br />
        <br />
        <p id="iua_establecimiento">Establecimiento: {{ data.info_alumno.establecimiento }}</p>
        <p id="iua_cursos">Curso/s: {{ data.info_alumno.cursos.join(", ") }}</p>

        <h3>
            <u>{{ "informe_alumno.informe.subtitulo_uso" | t }}</u>
        </h3>

        <p>{{ "informe_alumno.informe.detalle_uso" | t }}</p>

        <p>
            <b id="iua_n_ingresos">
                {{ "informe_alumno.informe.n_ingresos" | t }} {{ data.estadisticas_uso.cantidad_ingresos_plataforma }}
            </b>
            <br />
            <i>
                {{ "informe_alumno.informe.n_ingresos_desc" | t }}
            </i>
        </p>

        <br />
        <p>
            <b id="iua_n_mat_vistos">
                {{ "informe_alumno.informe.n_mat_vistos" | t }} {{ data.estadisticas_uso.cantidad_materiales_vistos }}
            </b>
        </p>
        <p>
            <b id="iua_n_mat_descargados">
                {{ "informe_alumno.informe.n_mat_descargados" | t }}
                {{ data.estadisticas_uso.cantidad_materiales_descargados }}
            </b>
        </p>
        <p>
            <b id="iua_tiempo_view_mat">
                {{ "informe_alumno.informe.tiempo_view_mat" | t }}
                {{ data.estadisticas_uso.tiempo_visualizacion_materiales | secondsToTime: "s":"hrminseg" }}
            </b>
        </p>
        <p>
            <b id="iua_n_sesiones_terminadas">
                {{ "informe_alumno.informe.n_sesiones_terminadas" | t }}
                {{ data.estadisticas_uso.cantidad_sesiones_planes_terminadas }}
            </b>
        </p>
        <p>
            <b id="iua_n_eval_respondidas">
                {{ "informe_alumno.informe.n_eval_respondidas" | t }}
                {{ data.estadisticas_uso.cantidad_evaluaciones_respondidas }}
            </b>
        </p>
        <br />
        <ng-container *ngIf="hasAchievements">
            <p>
                <b id="iua_n_logros_obtenidos">
                    {{ "informe_alumno.informe.logros_obtenidos" | t }} {{ data.estadisticas_uso.cantidad_logros }}
                </b>
                <br />
                <i>
                    {{ "informe_alumno.informe.logros_obtenidos_desc" | t }}
                </i>
            </p>
            <ul id="iua_logros_obtenidos">
                <li *ngFor="let logro of data.estadisticas_uso.logros">{{ logro }}</li>
            </ul>
        </ng-container>
    </div>
    <div *ngIf="usuarioId">
        <h3>
            <u>{{ "informe_alumno.informe.resultados.titulo" | t }}</u>
        </h3>
        <p>{{ "informe_alumno.informe.resultados.descripcion" | t }}</p>
        <h4>
            <b>{{ "informe_alumno.informe.resultados.resumen_gral.titulo" | t }}</b>
        </h4>
        <ng-container *ngFor="let ev of evaluacionTiposSelected">
            <h5>
                <b>{{ evaluacionTipoAliases[ev.evaluacion_tipo] | capitalize }}</b>
            </h5>
            <tabla-kpi [evaluacionTipo]="ev.evaluacion_tipo" [usuarioId]="usuarioId"
                [columns]="'n_ensayos,ultimo_ptje,maxima,promedio'" [fechaInicio]="fechaInicio" [fechaFin]="fechaFin"
                [useAsignaturasPeriodo]="true" [establecimientoId]="establecimientoId"></tabla-kpi>
        </ng-container>
    </div>
</loading-layout>