<ll-titulo>{{ "informe_alumno.titulo" | t }}</ll-titulo>

<filtro-periodo class="pull-right with-min-width" (select)="onSelectPeriodo($event)"></filtro-periodo>
<h3 class="h3_sub_title_2 littledown">{{ "informe_alumno.instrucciones" | t }}</h3>
<p class="tutorial">
    {{ "informe_alumno.instrucciones_desc" | t }}
</p>
<br />
<selector-usuarios-por-colegio (onEstablecimientoChanged)="changeEstablecimientoId($event)"
    (onGrupoUsuariosObjChanged)="changeGrupoUsuario($event)" (onUsuariosChanged)="changeUsuarioIds($event)">
</selector-usuarios-por-colegio>

<div class="row actions">
    <div class="col-md-6 col-sm-6 col-xs-12">
        <div class="tipos-instrumento">
            <h5>{{ "Tipo de instrumento" }}</h5>
            <checkboxes ngDefaultControl [options]="evaluacionTiposSelect" [optionsName]="evaluacionTipoSelectNames"
                [(ngModel)]="evaluacionTiposSelected">
            </checkboxes>
        </div>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-12">
        <div class="container-buttons">
            <cui-button id="descarga-grupo" [disabled]="!grupoUsuario" (click)="downloadInformeGrupo()"
                [ngClass]="{ 'btn-disabled': !grupoUsuario }">
                {{ "informe_alumno.descarga.button_grupo" | t }}
            </cui-button>
            <fa [name]="'question-circle-o'" [tooltip]="'informe_alumno.descarga.desc_button_grupo' | t"></fa>
        </div>
        <div class="container-buttons">
            <cui-button id="descarga-individual" [disabled]="!usuarioId" (click)="downloadInformeIndividual()"
                [ngClass]="{ 'btn-disabled': !usuarioId }">
                {{ "informe_alumno.descarga.button_individual" | t }}
            </cui-button>
            <fa [name]="'question-circle-o'" [tooltip]="'informe_alumno.descarga.desc_button_individual' | t"></fa>

        </div>
        <div class="container-buttons">
            <cui-button id="enviar-correo-grupo" [disabled]="!grupoUsuario" (click)="sendEmailGrupo()"
                [ngClass]="{ 'btn-disabled': !grupoUsuario }">
                {{ "informe_alumno.send_email.button_grupo" | t }}
            </cui-button>
            <fa [name]="'question-circle-o'" [tooltip]="'informe_alumno.send_email.desc_button_grupo' | t"></fa>
        </div>
    </div>
</div>

<div *ngIf="usuarioId" class="panel panel-default">
    <div class="panel-body">
        <informe-alumno [usuarioId]="usuarioId" [establecimientoId]="establecimientoId" [fechaInicio]="fechaInicio"
            [fechaFin]="fechaFin" [evaluacionTipos]="evaluacionTipos"
            [evaluacionTiposSelected]="evaluacionTiposSelected"></informe-alumno>
    </div>
</div>
<long-wait-loading-modal [errorMsg]="showErrorMessage">
    <div #messageElement ngProjectAs="message">
        <p class="loading-message" [innerHTML]="'informe_alumno.descarga.loading_message' | t | trustedhtml"></p>
    </div>

    <div #errorMessageElement ngProjectAs="errorMessage">
        <p class="error-message" [innerHTML]="'informe_alumno.descarga.error_message' | t | trustedhtml"></p>
    </div>
</long-wait-loading-modal>