import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { select, Store } from "@ngrx/store"
import { Estadisticas, EvaluacionTipo, GrupoUsuario } from "@puntaje/puntaje/api-services"
import { LongWaitLoadingModalComponent } from "@puntaje/puntaje/new-modules/shared"
import { selectAsignaturasByEvaluacionTipo, selectEvaluacionTipoAliases, State } from "@puntaje/puntaje/store"
import { AppConfig, GenericModalComponent } from "@puntaje/shared/core"
import { combineLatest, Subscription } from "rxjs"
import { filter, map } from "rxjs/operators"

@Component({
    templateUrl: "informe-alumno.component.html",
    styleUrls: ["informe-alumno.component.scss"]
})
export class InformeAlumnoComponent implements OnInit, OnDestroy {
    establecimientoId: number
    grupoUsuario: GrupoUsuario
    usuarioId: number
    fechaInicio: any = {}
    fechaFin: any = {}
    sub: Subscription
    subEvaluacionTipo: Subscription
    evaluacionTipos: string[]
    evaluacionTipoSelectNames: string[]
    evaluacionTiposSelect: EvaluacionTipo[]
    evaluacionTiposSelected: EvaluacionTipo[] = []
    
    evaluacionTipos$ = combineLatest([
        this.store.pipe(select(selectEvaluacionTipoAliases)),
        this.store.pipe(select(selectAsignaturasByEvaluacionTipo))
    ]).pipe(
        map(([evaluacionTipoAliases, asignaturasByEvaluacionTipo]) =>
            Object.keys(evaluacionTipoAliases).filter(et => asignaturasByEvaluacionTipo?.[et]?.length > 0)
        )
    )

    subAlias: Subscription
    evaluacionTipoAliases: any
    evaluacionTipoAliases$ = this.store.pipe(
        select(selectEvaluacionTipoAliases),
        filter(x => !!x)
    )

    showErrorMessage: boolean = false
    @ViewChild(LongWaitLoadingModalComponent)
    longWaitLoadingModal: LongWaitLoadingModalComponent

    constructor(protected estadisticasService: Estadisticas, protected store: Store<State>) { }

    ngOnInit() {
        this.subEvaluacionTipo = combineLatest([this.evaluacionTipoAliases$, this.evaluacionTipos$]).subscribe(
            ([evaluacionTipoAliases, evaluacionTipos]) => {
                this.evaluacionTipos = evaluacionTipos.filter(et => et.indexOf("diagnostico") == -1)
                this.evaluacionTiposSelect = this.evaluacionTipos.map((et, index) => {
                    let evaluacionTipo = new EvaluacionTipo()
                    evaluacionTipo.evaluacion_tipo = et
                    evaluacionTipo.id = index
                    return evaluacionTipo
                })
                this.evaluacionTiposSelected = this.evaluacionTiposSelect
    
                this.evaluacionTipoAliases = evaluacionTipoAliases
                this.evaluacionTipoSelectNames = this.evaluacionTipos.map(et => {
                    return this.evaluacionTipoAliases[et]
                })
            }
        )
    }

    changeUsuarioIds(ids: number[]) {
        if (ids && ids.length == 1) {
            this.usuarioId = ids[0]
        } else {
            this.usuarioId = null
        }
    }

    changeGrupoUsuario(grupoUsuarios: GrupoUsuario[]) {
        if (grupoUsuarios && grupoUsuarios.length == 1) {
            this.grupoUsuario = grupoUsuarios[0]
        } else {
            this.grupoUsuario = null
        }
    }

    changeEstablecimientoId(establecimientoId) {
        this.establecimientoId = establecimientoId
        this.usuarioId = null
    }

    onSelectPeriodo({ fechaInicial, fechaFinal }) {
        this.fechaInicio = fechaInicial
        this.fechaFin = fechaFinal
        this.usuarioId = null
    }

    /**
     * Descarga el informe de uso en pdf para el alumno seleccionado.
     */
    downloadInformeIndividual() {
        this.showErrorMessage = false
        this.longWaitLoadingModal.show()
        this.estadisticasService
            .imprimirInformeUsoAlumno(
                this.usuarioId,
                this.establecimientoId,
                this.fechaInicio,
                this.fechaFin,
                this.evaluacionTiposSelected.map(et => et.evaluacion_tipo)
            )
            .catch(e => {
                this.showErrorMessage = true
            })
            .finally(() => {
                this.longWaitLoadingModal.hide()
            })
    }

    /**
     * Descarga el informe de uso en pdf para el grupo_usuario seleccionado.
     */
    downloadInformeGrupo() {
        this.showErrorMessage = false
        this.longWaitLoadingModal.show()
        this.estadisticasService
            .imprimirInformeUsoGrupoUsuario(
                this.grupoUsuario.id,
                this.establecimientoId,
                this.fechaInicio,
                this.fechaFin,
                this.evaluacionTiposSelected.map(et => et.evaluacion_tipo)
            )
            .then(() => { })
            .catch(e => {
                this.showErrorMessage = true
            })
            .finally(() => {
                this.longWaitLoadingModal.hide()
            })
    }

    /**
     * Envia el informe de uso en pdf a alumnos para el grupo usuario seleccionado.
     * 
     */
    sendEmailGrupo() {
        this.showErrorMessage = false
        this.longWaitLoadingModal.show()
        this.estadisticasService
            .sendInformeUsoGrupoUsuario(
                this.grupoUsuario.id,
                this.establecimientoId,
                this.fechaInicio,
                this.fechaFin,
                this.evaluacionTiposSelected.map(et => et.evaluacion_tipo)
            )
            .then(() => {})
            .catch(e => {
                this.showErrorMessage = true
            })
            .finally(() => {
                this.longWaitLoadingModal.hide()
            })
    }

    ngOnDestroy() {
        this.sub.unsubscribe()
        this.subAlias.unsubscribe()
    }
}
