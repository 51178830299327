import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { AuthService, I18nService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "resultados-evaluacion",
    templateUrl: "./resultados-evaluacion.component.html",
    styleUrls: ["./resultados-evaluacion.component.scss"]
})
export class ResultadosEvaluacionComponent extends MenuComponent implements OnInit {
    menuItems: MenuItemBoxData[] = []
    tipoInstrumento: string

    constructor(
        protected store: Store<State>,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService,
        protected i18nService: I18nService
    ) {
        super(store)
    }

    ngOnInit() {
        this.tipoInstrumento = config.plataforma.evaluacionDefault
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        // Si se agregan otros items a una plataforma específica, considerar no agregarlo al archivo de traducción
        // dado que este archivo podría ser distinto plataforma por plataforma
        this.menuItems = [
            {
                route: "/evaluaciones/resultados/historial",
                params: {},
                label: this.i18nService.translate("resultados_evaluacion.historial_label"),
                text: this.i18nService.translate("resultados_evaluacion.historial_text"),
                linkText: this.i18nService.translate("resultados_evaluacion.historial_link_text"),
                icon: "guia"
            },
            {
                route: "/estadisticas",
                params: { tipo_instrumento: this.tipoInstrumento },
                label: this.i18nService.translate("resultados_evaluacion.estadisticas_label"),
                text: this.i18nService.translate("resultados_evaluacion.estadisticas_text"),
                linkText: this.i18nService.translate("resultados_evaluacion.estadisticas_link_text"),
                icon: "estadistica-o"
            },
            {
                route: "/pruebas/progreso",
                params: { tipo_instrumento: this.tipoInstrumento },
                label: this.i18nService.translate("resultados_evaluacion.progreso_label"),
                text: this.i18nService.translate("resultados_evaluacion.progreso_text"),
                linkText: this.i18nService.translate("resultados_evaluacion.progreso_link_text"),
                icon: "estadistica-o"
            },
            {
                route: "/evaluaciones/resultados/informe_alumno",
                params: {},
                label: this.i18nService.translate("resultados_evaluacion.informes_label"),
                text: this.i18nService.translate("resultados_evaluacion.informes_text"),
                linkText: this.i18nService.translate("resultados_evaluacion.informes_link_text"),
                icon: "estadistica-o"
            }
        ]
    }
}
